import dotEnv from "dotenv";
import { Env } from "./env.type";

export function readEnvFile(): Env {
  const currentEnvName = process.env.ENV_NAME || "local";
  const currentEnvObj = dotEnv.config({
    path: process.cwd() + `/env/.env.${currentEnvName}`,
  }).parsed;

  if (!currentEnvObj) {
    throw new Error("no env");
  }
  return currentEnvObj as unknown as Env;
}
