import { ENV_KEY } from "./env.constants";
import { Env } from "./env.type";
import { readEnvFile } from "./readEnvFile";

let env: Env | undefined;

export function getEnv(key: keyof Env) {
  const isClient = typeof window !== "undefined";

  if (isClient) {
    if (!env) {
      env = (window as any)[ENV_KEY];
      delete (window as any)[ENV_KEY];
    }
  } else {
    if (!env) {
      env = readEnvFile();
    }
  }
  return env?.[key];
}
